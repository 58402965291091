'use strict';

Gri.module({
  name: 'box-beta',
  ieVersion: null,
  $el: $('.box-beta'),
  container: '.box-beta',
  fn: function () {
    
  }
});
